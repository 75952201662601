import { useNavigate } from "react-router-dom";
import { useGetCategoryQuery } from "../redux/api/authApi";
import Skeleton from "../utils/components/Skeleton";

type Props = {};

const Categories = (props: Props) => {
  const { data, isLoading } = useGetCategoryQuery("size=8");
  const navigate = useNavigate();

  return (
    <div className="grid grid-cols-4 sm:grid-cols-8 gap-2 p-4">
      {isLoading ? (
        <Skeleton length={1} />
      ) : (
        data?.map((item, index) => {
          return (
            <div
              onClick={() => navigate(`/products?category=${item.id}`)}
              className="p-2 hover:shadow-lg shadow cursor-pointer"
              key={index}
            >
              <img
                className="h-18 w-18  lg:h-24 lg:w-24  object-cover mx-auto"
                src={item?.img}
                alt={item?.name}
              />
              <h6 className="text-xs font-semibold sm:text-sm md:text-base text-center text-gray-700">
                {item?.name}
              </h6>
            </div>
          );
        })
      )}
    </div>
  );
};

export default Categories;
