import { useEffect } from "react";

const FAQSection = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <div className="bg-gray-50 p-8 text-gray-800">
      <h1 className="text-3xl font-bold mb-6 text-center text-main">
        Frequently Asked Questions
      </h1>

      <div className="space-y-6">
        {/* English Section */}
        <section>
          <h2 className="text-2xl font-semibold mb-4">
            Are the products authentic?
          </h2>
          <p className="text-gray-700">
            – Cosmelic brings 100% genuine high-end & drugstore products which
            are directly sourced from the brands & authorized distributors.
          </p>
        </section>

        <section>
          <h2 className="text-2xl font-semibold mb-4">
            How do I place an order at Cosmelic Store?
          </h2>
          <ol className="list-decimal ml-6 text-gray-700 space-y-2">
            <li>Add to cart</li>
            <li>Place order</li>
            <li>Confirmation</li>
          </ol>
        </section>

        <section>
          <h2 className="text-2xl font-semibold mb-4">
            Can I use my Master/VISA/American Express card to purchase your
            products from your website?
          </h2>
          <p className="text-gray-700">– Yes, you can.</p>
        </section>

        <section>
          <h2 className="text-2xl font-semibold mb-4">
            Can I bKash the amount to purchase my desired product from the
            website?
          </h2>
          <p className="text-gray-700">
            – Yes, you can. bKash Number:{" "}
            <span className="font-bold">01880414733 (Personal)</span>.
          </p>
        </section>

        <section>
          <h2 className="text-2xl font-semibold mb-4">
            What is your delivery fee inside Dhaka?
          </h2>
          <p className="text-gray-700">– 70 taka.</p>
        </section>

        <section>
          <h2 className="text-2xl font-semibold mb-4">
            Do you deliver outside Dhaka? What’s the delivery fee for outside
            Dhaka?
          </h2>
          <p className="text-gray-700">
            – Yes, we deliver all over Bangladesh, and the delivery charge is
            110 taka. We use third-party courier services to deliver our
            products.
          </p>
        </section>

        {/* Bengali Section */}
        <h1 className="text-3xl font-bold mb-6 text-center">
          সচরাচর জিজ্ঞাসিত প্রশ্নাবলী
        </h1>

        <section>
          <h2 className="text-2xl font-semibold mb-4">
            কসমেলিকের প্রোডাক্ট কি অথেন্টিক?
          </h2>
          <p className="text-gray-700">
            – কসমেলিক স্বয়ং ব্র্যান্ড এবং অথোরাইজড ডিস্ট্রিবিউটর থেকে সংগৃহীত
            প্রিমিয়াম এবং ড্রাগ-স্টোর প্রোডাক্ট রিসেল করে যা ১০০% অথেন্টিক।
          </p>
        </section>

        <section>
          <h2 className="text-2xl font-semibold mb-4">
            আমি কিভাবে কসমেলিকে অর্ডার করতে পারি?
          </h2>
          <ol className="list-decimal ml-6 text-gray-700 space-y-2">
            <li>কার্টে এড</li>
            <li>অর্ডার প্লেইস</li>
            <li>নিশ্চিতকরণ (কনফার্মেশন)</li>
          </ol>
        </section>

        <section>
          <h2 className="text-2xl font-semibold mb-4">
            আমি কি আমার Master/VISA/ American Express ক্রেডিট কার্ড ব্যবহার করে
            আপনাদের ওয়েবসাইট থেকে প্রোডাক্ট কিনতে পারব?
          </h2>
          <p className="text-gray-700">– জ্বী, পারবেন।</p>
        </section>

        <section>
          <h2 className="text-2xl font-semibold mb-4">
            আমি কি বিকাশ পেমেন্টের মাধ্যমে আপনাদের ওয়েবসাইট থেকে প্রোডাক্ট কিনতে
            পারব?
          </h2>
          <p className="text-gray-700">
            – জ্বী, পারবেন। বিকাশ নাম্বার:{" "}
            <span className="font-bold">০১৮৮০৪১৪৭৩৩ (পার্সোনাল)</span>।
          </p>
        </section>

        <section>
          <h2 className="text-2xl font-semibold mb-4">
            ঢাকার ভিতরে আপনাদের ডেলিভারি ফী কত?
          </h2>
          <p className="text-gray-700">– ৭০ টাকা।</p>
        </section>

        <section>
          <h2 className="text-2xl font-semibold mb-4">
            আপনারা কি ঢাকার বাইরে ডেলিভারি করেন? ঢাকার বাইরে ডেলিভারি ফী কত?
          </h2>
          <p className="text-gray-700">
            – জ্বী, আমরা সারা বাংলাদেশে ডেলিভারি করে থাকি এবং ডেলিভারি চার্জ ১১০
            টাকা। আমাদের পণ্য ডেলিভারি করার জন্য আমরা থার্ড পার্টি লজিস্টিক
            কোম্পানি ব্যবহার করে থাকি।
          </p>
        </section>
      </div>
    </div>
  );
};

export default FAQSection;
