import { useEffect, useState } from "react";
import { MdOutlineNavigateBefore, MdOutlineNavigateNext } from "react-icons/md";

import { useLocation } from "react-router-dom";
import AllCategories from "../components/AllCategories";
import Product2 from "../components/Product";
import { useGetProductsQuery } from "../redux/api/authApi";
import Skeleton from "../utils/components/Skeleton";

type Props = {};

function ProductPage(props: Props) {
  const [page, setPage] = useState(1);
  const size = 20;
  // const [size, setSize] = useState(20);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortOption, setSortOption] = useState("");
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const category = queryParams.get("category");
  const searchText = queryParams.get("search");

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  useEffect(() => {
    setSearchTerm(searchText as string);
  }, [searchText]);

  let query = `page=${page}&size=${size}&sort=${sortOption}&search=${
    searchTerm || ""
  }`;

  if (category) {
    query += `&cate_id=${category || undefined}`;
  }

  const { data, isLoading } = useGetProductsQuery(query);

  const products = data?.data;
  const count = data?.count || 20;

  const totalPages = Math.ceil(count / size);

  const handleNext = () => {
    if (page < totalPages) {
      setPage(page + 1);
    }
  };

  const handlePrevious = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  const handleSort = (e: any) => {
    setSortOption(e.target.value);
  };

  const handleSearch = (e: any) => {
    const value = e.target.value;

    setSearchTerm(value);
  };

  return (
    <div className="container mx-auto p-2">
      <AllCategories />

      <div className="flex flex-col sm:flex-row justify-between items-center mt-4 sm:mt-4 md:mt-8 lg:mt-12">
        {/* Search Input */}
        <div className="relative flex items-center mb-2 w-full lg:w-1/4">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            className="absolute w-5 h-5 top-2.5 left-2.5 text-slate-600"
          >
            <path
              fill-rule="evenodd"
              d="M10.5 3.75a6.75 6.75 0 1 0 0 13.5 6.75 6.75 0 0 0 0-13.5ZM2.25 10.5a8.25 8.25 0 1 1 14.59 5.28l4.69 4.69a.75.75 0 1 1-1.06 1.06l-4.69-4.69A8.25 8.25 0 0 1 2.25 10.5Z"
              clip-rule="evenodd"
            />
          </svg>

          <input
            value={searchTerm || ""}
            onChange={handleSearch}
            className="w-full  bg-white placeholder:text-slate-400 text-slate-700 text-sm border border-slate-200 rounded-md pl-10 pr-3 py-2 transition duration-300 ease focus:outline-none focus:border-slate-400 hover:border-slate-300 shadow-sm focus:shadow"
            placeholder="I'm looking for..."
          />
        </div>

        {/* Sort Select Input */}
        <select
          className="bg-white placeholder:text-slate-400 text-slate-700 text-sm border border-slate-200 rounded-md py-2 transition duration-300 ease mb-2 w-full lg:w-1/4 focus:outline-none focus:border-slate-400 hover:border-slate-300 shadow-sm focus:shadow"
          value={sortOption}
          onChange={handleSort}
        >
          <option value="">Sort By</option>
          <option value="price-asc">Price: Low to High</option>
          <option value="price-desc">Price: High to Low</option>
          <option value="name-asc">Name: A to Z</option>
          <option value="name-desc">Name: Z to A</option>
        </select>
      </div>
      {isLoading ? (
        <Skeleton />
      ) : (
        <section className="text-gray-700 bg-white lg:mt-12 md:mt-8 mt-4">
          <div className="grid grid-cols-2 lg:grid-cols-4 lg:gap-8 md:gap-6 gap-2 lg:p-6 md:p-4 p-2">
            {products?.map((product) => (
              <Product2 key={product.id} product={product} />
            ))}
          </div>
        </section>
      )}

      <div className="flex justify-center items-center space-x-4 my-4">
        <button
          className={`px-4 py-1 text-white bg-main rounded ${
            page === 1 && "opacity-50 cursor-not-allowed"
          }`}
          onClick={handlePrevious}
          disabled={page === 1}
        >
          <MdOutlineNavigateBefore />
        </button>

        <span className="text-base font-semibold text-gray-800">
          Page {page} of {totalPages}
        </span>

        <button
          className={`px-4 py-1 text-white bg-main rounded ${
            page === totalPages && "opacity-50 cursor-not-allowed"
          }`}
          onClick={handleNext}
          disabled={page === totalPages}
        >
          <MdOutlineNavigateNext />
        </button>
      </div>
    </div>
  );
}

export default ProductPage;
