import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from "../assest/logo.png";
import "../styles/navbar.css";
import Drawer from "./Drawer";

function NavBar() {
  const [searchText, setSearchText] = useState("");
  const navigate = useNavigate();

  const handleSearch = () => {
    navigate(`/products?search=${searchText}`);
  };

  const scrollToCategory = () => {
    navigate("/");
    window.scrollTo({
      top: 1100,
      behavior: "smooth",
    });
  };

  return (
    <header className="shadow bg-white">
      <div className="text-slate-700 relative mx-auto flex flex-col overflow-hidden px-4 py-2 lg:flex-row lg:items-center container ">
        <input type="checkbox" className="peer hidden" id="navbar-open" />

        <div className="flex justify-between items-center w-full">
          <label className="cursor-pointer lg:hidden" htmlFor="navbar-open">
            <svg
              className="h-7 w-7"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1.5"
                d="M4 6h16M4 12h16M4 18h16"
              ></path>
            </svg>
          </label>

          <Link to="/" className="w-36">
            <img className="w-full" src={logo} alt="" />
          </Link>

          <span className="lg:hidden sm:block">
            <Drawer />
          </span>
        </div>

        <nav
          aria-label="Header Navigation"
          className="peer-checked:pt-8 peer-checked:max-h-60 flex max-h-0 w-full flex-col items-center overflow-hidden transition-all lg:ml-24 lg:max-h-full lg:flex-row"
        >
          <hr className="mt-4 w-full lg:hidden" />
          <ul className="flex flex-col items-center space-y-2 md:ml-auto md:flex-row md:space-y-0">
            <li className=" md:mr-12 hover:text-orange-600 font-bold text-main">
              <Link to={"/"}>Home</Link>
            </li>
            <li className=" md:mr-12 hover:text-orange-600 font-bold text-main">
              <Link to={"/products"}>Products</Link>
            </li>
            <li className=" md:mr-12 hover:text-orange-600 font-bold text-main">
              <button onClick={scrollToCategory}>Category</button>
            </li>
          </ul>
        </nav>

        {/* SEARCH INPUT */}
        <div className="w-full max-w-sm min-w-[200px] mt-2">
          <div className="relative flex items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              className="absolute w-5 h-5 top-2.5 left-2.5 text-slate-600"
            >
              <path
                fill-rule="evenodd"
                d="M10.5 3.75a6.75 6.75 0 1 0 0 13.5 6.75 6.75 0 0 0 0-13.5ZM2.25 10.5a8.25 8.25 0 1 1 14.59 5.28l4.69 4.69a.75.75 0 1 1-1.06 1.06l-4.69-4.69A8.25 8.25 0 0 1 2.25 10.5Z"
                clip-rule="evenodd"
              />
            </svg>

            <input
              onChange={(e) => setSearchText(e.target.value)}
              className="w-full bg-transparent placeholder:text-slate-400 text-slate-700 text-sm border border-slate-200 rounded-md pl-10 pr-3 py-2 transition duration-300 ease focus:outline-none focus:border-slate-400 hover:border-slate-300 shadow-sm focus:shadow"
              placeholder="I'm looking for..."
            />

            <button
              onClick={handleSearch}
              className="absolute right-1 top-1 rounded bg-main p-1.5 border border-transparent text-center text-sm text-white transition-all shadow-sm hover:shadow focus:shadow-none active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
              type="button"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 16 16"
                fill="currentColor"
                className="w-4 h-4"
              >
                <path
                  fill-rule="evenodd"
                  d="M9.965 11.026a5 5 0 1 1 1.06-1.06l2.755 2.754a.75.75 0 1 1-1.06 1.06l-2.755-2.754ZM10.5 7a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0Z"
                  clip-rule="evenodd"
                ></path>
              </svg>
            </button>
          </div>
        </div>

        <span className="hidden lg:block">
          <Drawer />
        </span>
      </div>
    </header>
  );
}

export default NavBar;
