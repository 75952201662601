import { Link } from "react-router-dom";
import Categories from "../components/Categories";
import HeroBanner from "../components/HeroBanner";
import LatestProducts from "../components/LatestProducts";
import Products from "../components/Products";
import ScrollToTopButton from "../utils/components/ScrollToTopButton";
type Props = {};

const Home = (props: Props) => {
  return (
    <div className="container mx-auto">
      <ScrollToTopButton />
      <main>
        <HeroBanner />
        <LatestProducts />

        <div className="flex items-center justify-between p-4 border mt-4">
          <h4 className="font-title text-gray-800">CATEGORY</h4>
          <Link to="/products">
            <button className="px-6 py-2 text-white font-semibold rounded-md hover:bg-purple-800 transition duration-300 bg-main">
              More
            </button>
          </Link>
        </div>

        <Categories />

        <Products />
      </main>
    </div>
  );
};

export default Home;
