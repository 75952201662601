import React from "react";

function Skeleton({ length = 2 }: { length?: number }) {
  return (
    <div className="p-2">
      {Array.from({ length: length }, (_, index) => (
        <>
          <br />
          <span className="block h-10 w-90 rounded-lg bg-gradient-to-r from-gray-200 to-gray-400/25 animate-pulse"></span>
          <br />
          <span className="block h-4 w-90 rounded bg-gradient-to-r from-gray-200 to-gray-400/25 animate-pulse"></span>
        </>
      ))}
    </div>
  );
}

export default Skeleton;
