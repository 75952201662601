import { useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useGetCategoryQuery } from "../redux/api/authApi";

type Props = {};

const AllCategories = (props: Props) => {
  const { data, isLoading } = useGetCategoryQuery("size=all");
  const [category, setCategory] = useState<null | number>(null);

  const [searchParams, setSearchParams] = useSearchParams();

  const handleSelect = (category: number) => {
    setCategory(category);
    setSearchParams({ category: category.toString() });
  };

  const scrollContainerRef = useRef<HTMLDivElement>(null);
  if (isLoading) {
    return (
      <div className="bg-gray-200 md:h-56 sm:h-24 flex items-center justify-center">
        <div className="rounded-md h-12 w-12 border-4 border-t-4 border-blue-500 animate-spin"></div>
      </div>
    );
  }

  // Function to scroll left
  const scrollLeft = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({
        left: -300, // Adjust scroll distance as needed
        behavior: "smooth",
      });
    }
  };

  // Function to scroll right
  const scrollRight = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({
        left: 300, // Adjust scroll distance as needed
        behavior: "smooth",
      });
    }
  };

  return (
    <div className="relative">
      {/* Left Scroll Button */}
      <button
        onClick={scrollLeft}
        className="absolute left-0 top-1/2 transform -translate-y-1/2 p-2 bg-gray-800 text-white rounded-full z-10"
      >
        &#8249;
      </button>

      {/* Right Scroll Button */}
      <button
        onClick={scrollRight}
        className="absolute right-0 top-1/2 transform -translate-y-1/2 p-2 bg-gray-800 text-white rounded-full z-10"
      >
        &#8250;
      </button>

      <div
        ref={scrollContainerRef}
        className="overflow-x-auto scroll-smooth flex space-x-4 scrollbar-hide"
      >
        {data?.map((item, index: number) => (
          <div
            key={index}
            onClick={() => handleSelect(item.id)}
            className={`min-w-[100px]  hover:bg-gray-300 transition ${
              category === item.id ? "bg-[#FFF7D1]" : "bg-gray-200"
            }`}
          >
            <div className="relative p-4 cursor-pointer">
              <div className="relative aspect-square overflow-hidden group">
                <img
                  className="h-full w-full object-cover"
                  src={item?.img}
                  alt={item?.name}
                />
              </div>

              <h3 className="text-xs font-semibold mt-4 text-gray-600 text-center">
                {item?.name}
              </h3>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AllCategories;
