import { IProductDetailsInterface } from "../../interfaces/common.interfaces";

// gtm.js
export const pushToDataLayer = (event: any, eventData = {}) => {
  if (window && window.dataLayer) {
    window.dataLayer.push({
      event,
      ...eventData,
    });
  } else {
    console.warn("GTM dataLayer not found");
  }
};

// gtm.js
export const pushProductViewToDataLayer = (
  product: IProductDetailsInterface
) => {
  if (window && window.dataLayer) {
    window.dataLayer.push({
      event: "product_view",
      ecommerce: {
        items: [product],
      },
    });
  } else {
    console.warn("GTM dataLayer not found");
  }
};
