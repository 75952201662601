import { useEffect, useState } from "react";
import { TbCurrencyTaka } from "react-icons/tb";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useLazyPlaceOrderQuery } from "../redux/api/authApi";
import { emptyCart } from "../redux/slices/cartSlice";
import { RootState } from "../redux/store/store";
import { pushToDataLayer } from "../utils/ga/gtm";
import { dhakaDivisionDistricts, districts } from "../utils/utils";

const Checkout = () => {
  const [errorMessage, setErrorMessage] = useState("");
  const cartItems = useSelector((state: RootState) => state.cart.items);
  const dispatch = useDispatch();

  const handlePurchase = (value: any) => {
    window.fbq("track", "Purchase", value);
  };

  const trackCustomEvent = (cusomerInfo: any) => {
    if (window.fbq) {
      window.fbq("trackCustom", "FormSubmission", cusomerInfo);
    }
  };

  const emptyCartHan = () => {
    dispatch(emptyCart());
  };

  const navigate = useNavigate();

  const [placeOrder, { isError, isSuccess, isLoading }] =
    useLazyPlaceOrderQuery();

  useEffect(() => {
    if (isSuccess) {
      emptyCartHan();
      navigate("/thank-you");
    }
    if (isError) {
      console.log({
        message: "Thare was an error!",
        type: "error",
        duration: 400,
      });
    }
  }, [isError, isSuccess, isLoading]);

  const [shippingCost, setShippingCost] = useState(80);

  const districtSelectHandler = (dis: string) => {
    if (dhakaDivisionDistricts.includes(dis)) {
      setShippingCost(80);
    } else {
      setShippingCost(120);
    }
  };

  // Function to calculate total, discount, and net prices
  const calculateTotals = () => {
    return cartItems.reduce(
      (acc, item) => {
        const itemTotalPrice = Number(item.regular_price) * item.quantity;
        const itemTotalDiscount =
          (Number(item.regular_price) -
            (Number(item?.offer_price) || Number(item.regular_price))) *
          item.quantity;
        const itemNetPrice =
          (Number(item.offer_price) || Number(item.regular_price)) *
          item.quantity;

        return {
          sub_total: acc.sub_total + itemTotalPrice,
          discount: acc.discount + itemTotalDiscount,
          net_total: acc.net_total + itemNetPrice,
        };
      },
      { sub_total: 0, discount: 0, net_total: 0 } // Initial values
    );
  };

  const { net_total, discount, sub_total } = calculateTotals();

  const [formData, setFormData] = useState({
    o_cus_name: "",
    o_cus_mobile: "",
    o_cus_city: "Dhaka",
    o_cus_address: "",
  });

  // Handle input change
  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const validateMobileNumber = (mobile: string) => {
    // Check if the number starts with +8801
    if (mobile.startsWith("+8801")) {
      if (mobile.length !== 13) {
        setErrorMessage("Invalid mobile number.");
        return;
      }
    }
    // Check if the number starts with 01
    else if (mobile.startsWith("01")) {
      if (mobile.length !== 11) {
        setErrorMessage("Invalid mobile number.");
        return;
      }
    }
    // If it doesn't match the format
    else {
      setErrorMessage("Mobile number must start with +880 or 01.");
      return;
    }

    // Clear error message if validation passes
    setErrorMessage("");
  };

  useEffect(() => {
    if (formData.o_cus_mobile) {
      validateMobileNumber(formData.o_cus_mobile);
    }
  }, [formData.o_cus_mobile]);

  // Handle form submission
  const handleSubmit = () => {
    const items = cartItems.map((item) => {
      return {
        oi_product_id: item.id,
        oi_qty: item.quantity,
        oi_regulr_price: Number(item.regular_price),
        oi_offer_price: Number(item.offer_price),
      };
    });

    const data = {
      ...formData,
      o_sub_total: sub_total,
      o_discount: discount,
      o_shipping_cost: shippingCost,
      o_net_total: net_total,
      items,
    };

    trackCustomEvent(formData);

    handlePurchase(data);

    placeOrder(data);
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  // confirmation
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleConfirm = () => {
    // Proceed with form submission or order placement
    console.log("Order Placed!");
    setIsModalOpen(false); // Close the modal after confirmation
    pushToDataLayer("button_click", { ...formData });
    handleSubmit();
  };

  const handleCancel = () => {
    setIsModalOpen(false); // Close the modal without proceeding
  };

  return (
    <div>
      <div className="mx-auto w-full bg-white container">
        <div className="grid min-h-screen grid-cols-10">
          <div className="relative col-span-full flex flex-col py-4 pl-8 pr-4 lg:col-span-4 lg:py-12 text-gray-700">
            <h2 className="sr-only">Order summary</h2>
            <div>
              <div className="absolute inset-0 h-full w-full"></div>
            </div>
            <div className="relative">
              <ul className="space-y-5">
                {cartItems?.map((item, index) => {
                  return (
                    <li
                      key={index}
                      className="flex justify-between shadow-md p-2"
                    >
                      <div className="inline-flex">
                        <img src={item.image_url} alt="" className="max-h-16" />
                        <div className="ml-3">
                          <p className="text-base font-semibold ">
                            {item.name}
                          </p>
                        </div>
                      </div>

                      <div className="text-right">
                        {item.offer_price ? (
                          <del className="mt-px text-xs font-semibold  sm:text-sm flex items-center">
                            <TbCurrencyTaka className="text-lg" />
                            {Number(item.regular_price)}
                          </del>
                        ) : (
                          <></>
                        )}

                        <p className="text-sm font-semibold  flex items-center">
                          <TbCurrencyTaka className="text-xl" />
                          {Number(item.offer_price || item.regular_price)}
                        </p>
                      </div>
                    </li>
                  );
                })}
              </ul>
              <div className="my-5 h-0.5 w-full bg-main bg-opacity-30"></div>
              <div className="space-y-2">
                <p className="flex justify-between text-lg font-bold ">
                  <span>Total price:</span>
                  <span className="flex items-center">
                    <TbCurrencyTaka className="text-xl" /> {sub_total}
                  </span>
                </p>
                <p className="flex justify-between text-lg font-bold ">
                  <span>Discount total</span>
                  <span className="flex items-center">
                    <TbCurrencyTaka className="text-xl" /> {discount}
                  </span>
                </p>
                <p className="flex justify-between text-lg font-bold ">
                  <span>Shipping cost</span>
                  <span className="flex items-center">
                    <TbCurrencyTaka className="text-xl" /> {shippingCost}
                  </span>
                </p>
                <p className="flex justify-between text-lg font-bold ">
                  <span>Net total</span>
                  <span className="flex items-center">
                    <TbCurrencyTaka className="text-xl" />
                    {net_total - shippingCost}
                  </span>
                </p>
              </div>
            </div>
          </div>

          <div className="col-span-full py-6 px-4 sm:py-12 lg:col-span-6 lg:py-12">
            <div className="mx-auto w-full max-w-lg">
              <h1 className="relative text-2xl font-medium text-gray-700 sm:text-3xl">
                Secure Checkout
                <span className="mt-2 block h-1 w-10 bg-main sm:w-20"></span>
              </h1>
              <form className="mt-10 flex flex-col space-y-4">
                <div>
                  <label className="text-xs font-semibold text-gray-700">
                    Full Name
                  </label>
                  <input
                    required
                    name="o_cus_name"
                    value={formData.o_cus_name}
                    onChange={handleChange}
                    type="text"
                    placeholder="John doe"
                    className="mt-1 block w-full rounded border-gray-500  py-3 px-4 text-sm placeholder-gray-300 shadow-sm outline-none transition focus:ring-2 "
                  />
                </div>
                <div>
                  <label className="text-xs font-semibold text-gray-700">
                    Mobile
                  </label>
                  <input
                    required
                    name="o_cus_mobile"
                    value={formData.o_cus_mobile}
                    onChange={handleChange}
                    type="text"
                    placeholder="Enter your mobile"
                    className="mt-1 block w-full rounded border-gray-500  py-3 px-4 text-sm placeholder-gray-300 shadow-sm outline-none transition focus:ring-2 "
                  />

                  {errorMessage && (
                    <p className="text-red-500 text-sm mt-1">{errorMessage}</p>
                  )}
                </div>

                <div>
                  <label className="text-xs font-semibold text-gray-700">
                    Select city
                  </label>
                  <select
                    name="o_cus_city"
                    value={formData.o_cus_city}
                    onChange={(e) => {
                      handleChange(e);
                      districtSelectHandler(e.target.value);
                    }}
                    className="mt-1 block w-full rounded shadow-sm outline-none transition focus:ring-2 "
                  >
                    {districts.map((item, index) => (
                      <option key={index} value={item}>
                        {item}
                      </option>
                    ))}
                  </select>
                </div>

                <div>
                  <label className="text-xs font-semibold text-gray-700">
                    Address
                  </label>
                  <input
                    required
                    name="o_cus_address"
                    value={formData.o_cus_address}
                    onChange={handleChange}
                    type="text"
                    placeholder="Enter your address"
                    className="mt-1 block w-full rounded border-gray-500  py-3 px-4 text-sm placeholder-gray-300 shadow-sm outline-none transition focus:ring-2 "
                  />
                </div>
                <p className="text-center text-sm font-semibold text-gray-700">
                  By placing this order you agree to the{" "}
                  <a className="whitespace-nowrap text-main ">
                    Terms and Conditions
                  </a>
                </p>
                <button
                  onClick={(e) => {
                    e.preventDefault();

                    setIsModalOpen(true);
                  }}
                  className="mt-4 inline-flex w-full items-center justify-center rounded py-2.5 px-4 text-base font-semibold tracking-wide text-white text-opacity-80 outline-none ring-offset-2 transition hover:text-opacity-100 focus:ring-2 sm:text-lg bg-main "
                >
                  Place Order
                </button>
                {/* Confirmation Modal */}
                {isModalOpen && (
                  <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
                    <div className="bg-white rounded-lg shadow-lg p-6 w-full max-w-md mx-auto">
                      <h2 className="text-xl font-semibold mb-4">
                        Confirm Order
                      </h2>
                      <p className="mb-6">Are you sure to place the order?</p>
                      <div className="flex justify-end">
                        <button
                          className="px-4 py-2 bg-gray-300 text-gray-800 rounded mr-4"
                          onClick={handleCancel}
                        >
                          Cancel
                        </button>
                        <button
                          className="px-4 py-2 bg-main text-white rounded"
                          onClick={handleConfirm}
                        >
                          Confirm
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Checkout;
