import React, { useEffect } from "react";

const ShippingDelivery = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <div className="bg-gray-50 p-8 text-gray-800">
      <h1 className="text-3xl font-bold mb-6 text-center text-main">
        Shipping & Delivery
      </h1>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-4">
          How does the delivery process work?
        </h2>
        <p className="text-gray-700 mb-4">
          Once our system processes your order, your products are inspected
          thoroughly to ensure they are in perfect condition. After they pass
          through the final round of quality checks, they are packed and handed
          over to our trusted delivery partner.
        </p>
        <p className="text-gray-700 mb-4">
          Our delivery partners then bring the package to you at the earliest
          possible time. In case they are unable to reach your provided address
          or deliver at a suitable time, they will contact you to resolve the
          issue.
        </p>
        <p className="text-gray-700 mb-4">
          We maintain a ‘closed box delivery’ policy, which ensures the
          authenticity of the products, protects customer privacy, and prevents
          product adulteration or modification.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-4">How are items packaged?</h2>
        <p className="text-gray-700 mb-4">
          We package our products in cardboard boxes along with your invoice.
          Each individual product is carefully packed, while fragile items like
          bottles are secured with bubble wrap for safe delivery.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-4">
          What are the delivery charges?
        </h2>
        <ul className="list-disc pl-6 text-gray-700 mb-4">
          <li>Inside Dhaka: 66 BDT</li>
          <li>Outside Dhaka: 99 BDT</li>
        </ul>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-4">
          What is the estimated delivery time?
        </h2>
        <ul className="list-disc pl-6 text-gray-700 mb-4">
          <li>Inside Dhaka: 1-2 days</li>
          <li>Outside Dhaka: 3-5 days</li>
        </ul>
        <p className="text-gray-700">
          However, delivery may be delayed due to political, environmental,
          transportation, or any other unavoidable issues. Our customer
          relationship team will notify you in such cases.
        </p>
      </section>

      <footer className="mt-8 text-center text-gray-500">
        Last updated: October 5, 2024
      </footer>
    </div>
  );
};

export default ShippingDelivery;
