import React from "react";
import { Link } from "react-router-dom";

const ThankYouPage: React.FC = () => {
  return (
    <div className="h-[80vh] flex flex-col justify-center items-center bg-gray-100">
      <div className="bg-white shadow-lg rounded-lg p-8 text-center max-w-md w-full">
        <h1 className="text-4xl font-bold text-main mb-4">Thank You!</h1>
        <p className="text-lg text-gray-600 mb-6">
          We appreciate your purchase! Your order has been placed successfully.
        </p>

        <div className="border-t border-gray-300 my-4"></div>

        <h2 className="text-2xl font-bold mb-4 text-gray-800">Contact Us</h2>
        <p className="text-gray-700">
          <strong>Email:</strong> info.cosmelic@gmail.com
        </p>
        <p className="text-gray-700">
          <strong>Phone:</strong> +880 1626 074776
        </p>

        <div className="border-t border-gray-300 my-4"></div>

        <Link to={"/"}>
          <button className="bg-main text-white px-6 py-2 rounded-lg hover:bg-fuchsia-500 transition">
            Continue Shopping
          </button>
        </Link>
      </div>
    </div>
  );
};

export default ThankYouPage;
