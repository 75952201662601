import { Helmet } from "react-helmet";
import { TbCurrencyTaka } from "react-icons/tb";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import {
  ICartProducts,
  IProductInterface,
} from "../interfaces/common.interfaces";
import { addProduct } from "../redux/slices/cartSlice";
import { AppDispatch } from "../redux/store/store";

type Props = {
  product: IProductInterface;
};

const Product = ({ product }: Props) => {
  const {
    id,
    image_url,
    name,
    regular_price,
    offer_price,
    off_discount,
    details,
  } = product;

  const dispatch: AppDispatch = useDispatch();

  const trackAddToCart = (product: any) => {
    if (window.fbq) {
      window.fbq("track", "AddToCart", {
        content_ids: [product.id],
        content_type: "product",
        value: product.price,
        currency: "USD",
      });
    }
  };

  const addToCart = (product: ICartProducts) => {
    trackAddToCart(product);
    dispatch(addProduct(product));
  };

  return (
    <div>
      <Helmet>
        <title>{name}</title>
        <meta name="description" content={details} />
      </Helmet>

      <Link to={`/products/details/${id}`}>
        <div className="relative shadow p-2 lg:p-4 hover:shadow-lg cursor-pointer bg-gray-200">
          <div className="relative aspect-square overflow-hidden group">
            <img
              className="group-hover:scale-125 h-full w-full object-cover transition-all duration-300"
              src={image_url}
              alt=""
            />
          </div>

          {off_discount ? (
            <div className="absolute top-0 m-1 rounded-full bg-white">
              <p className="text-[10px] rounded-full bg-black p-1 font-bold uppercase tracking-wide text-white sm:px-3 sm:py-1">
                {off_discount}% off
              </p>
            </div>
          ) : (
            <></>
          )}

          <h3 className="text-xs font-semibold sm:text-sm md:text-base mt-4 text-center">
            {name}
            <span className="absolute" aria-hidden="true"></span>
          </h3>

          <div className="mt-2 flex items-center justify-between flex-col lg:flex-row">
            <div className="text-right flex items-center">
              {offer_price ? (
                <del className="mt-px text-xs font-semibold text-gray-600 sm:text-sm whitespace-nowrap mr-2 flex items-center">
                  <TbCurrencyTaka className="text-base" />{" "}
                  {Number(regular_price)}
                </del>
              ) : (
                <></>
              )}

              <p className="text-xs font-bold sm:text-sm md:text-lg whitespace-nowrap flex items-center text-[#FF3C00]">
                <TbCurrencyTaka className="text-xl" />{" "}
                {Number(offer_price || regular_price)}
              </p>
            </div>
            <Link to={"/checkout"}>
              <button
                onClick={(e) => {
                  addToCart({ ...product, quantity: 1 });
                }}
                className="bg-main px-2 py-1 text-white rounded hover:bg-orange-500 mt-2 lg:mt-0"
              >
                Buy Now
              </button>
            </Link>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default Product;
