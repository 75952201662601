import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import "./App.css";
import Checkout2 from "./components/Checkout";
import ProductDetails from "./components/ProductDetails";
import Registration from "./components/Registration";
import ThankYouPage from "./components/ThankYouPage";
import FAQSection from "./pages/Faq";
import Home from "./pages/Home";
import Layout from "./pages/Layout";
import NotFound from "./pages/NotFound";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import ProductPage from "./pages/ProductPage";
import RefundReturnPolicy from "./pages/RefundReturnPolicy";
import ShippingDelivery from "./pages/ShippingDelivery";
import TermsAndConditions from "./pages/TermsAndConditions";

const trackingId = "G-BLGNF8293M";

declare global {
  interface Window {
    fbq: any;
    dataLayer: any;
  }
}

function App() {
  return (
    <div className="bg-[#F5F5F5]">
      <Router>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="/products" element={<ProductPage />} />
            <Route
              path="/products/details/:product_id"
              element={<ProductDetails />}
            />
            <Route path="/thank-you" element={<ThankYouPage />} />
            <Route path="/checkout" element={<Checkout2 />} />
            <Route path="/registration" element={<Registration />} />
            <Route path="/faq" element={<FAQSection />} />
            <Route path="/t&c" element={<TermsAndConditions />} />
            <Route path="/refund-return" element={<RefundReturnPolicy />} />
            <Route path="/shipping" element={<ShippingDelivery />} />
            <Route path="/privecy" element={<PrivacyPolicy />} />
          </Route>

          <Route path="*" element={<NotFound />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
