import { Outlet, useLocation } from "react-router-dom";
import FooterPage from "../components/FooterPage";
import NavBar from "../components/NavBar";

const Layout = () => {
  const location = useLocation();
  const isNotFound = location.pathname === "*";

  return (
    <div>
      {!isNotFound && <NavBar />}
      <Outlet />
      <FooterPage />
    </div>
  );
};

export default Layout;
