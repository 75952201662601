import { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { BsCashCoin } from "react-icons/bs";
import { FaAward } from "react-icons/fa6";
import { GiReturnArrow } from "react-icons/gi";
import { TbCurrencyTaka, TbTruckDelivery } from "react-icons/tb";
import { useDispatch } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ICartProducts } from "../interfaces/common.interfaces";
import {
  useGetProductsDetailsQuery,
  useGetProductsQuery,
} from "../redux/api/authApi";
import { addProduct } from "../redux/slices/cartSlice";
import { AppDispatch } from "../redux/store/store";
import { pushProductViewToDataLayer } from "../utils/ga/gtm";

type Props = {};

function ProductDetails({}: Props) {
  const [index, setIndex] = useState(0);
  const [quantity, setQuantity] = useState(1);
  const { product_id } = useParams<{ product_id: string }>();

  const { data, isLoading } = useGetProductsDetailsQuery(Number(product_id), {
    skip: !product_id,
  });

  const productDetails = data?.data;
  const { data: pData } = useGetProductsQuery(
    `cate_id=${productDetails?.category_id}`
  );

  const products = pData?.data;

  // scroll to
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const images = productDetails?.images;

  const dispatch: AppDispatch = useDispatch();

  const addToCart = (product: ICartProducts) => {
    dispatch(addProduct(product));
  };

  const navigate = useNavigate();
  const buyNowHan = (product: ICartProducts) => {
    addToCart(product);
    navigate("/checkout");
  };

  const cartProduct: any = {
    category_id: productDetails?.category_id as number,
    category_name: productDetails?.category_name as string,
    created_at: productDetails?.created_at as string,
    created_by: productDetails?.created_by,
    details: productDetails?.details,
    id: productDetails?.id,
    image_url: productDetails?.images,
    name: productDetails?.name,
    off_discount: productDetails?.off_discount,
    offer_price: productDetails?.offer_price,
    quantity,
    rating_value: productDetails?.rating_value,
    regular_price: productDetails?.regular_price,
    total_reviews: productDetails?.total_reviews,
    updated_at: productDetails?.updated_at,
  };

  // ==============SCROLL=============
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  // Function to scroll left
  const scrollLeft = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({
        left: -300, // Adjust scroll distance as needed
        behavior: "smooth",
      });
    }
  };

  // Function to scroll right
  const scrollRight = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({
        left: 300, // Adjust scroll distance as needed
        behavior: "smooth",
      });
    }
  };

  if (isLoading) {
    return (
      <div className="p-4">
        <div>
          <span className="block h-10 w-72 rounded-lg bg-gradient-to-r from-gray-200 to-gray-400/25 animate-pulse"></span>
          <br />
          <span className="block h-4 w-90 rounded bg-gradient-to-r from-gray-200 to-gray-400/25 animate-pulse"></span>
          <br />
          <span className="block h-10 w-72 rounded-lg bg-gradient-to-r from-gray-200 to-gray-400/25 animate-pulse"></span>
          <br />
          <span className="block h-4 w-90 rounded-lg bg-gradient-to-r from-gray-200 to-gray-400/25 animate-pulse"></span>
        </div>
      </div>
    );
  }

  if (productDetails) {
    pushProductViewToDataLayer(productDetails);
  }

  return (
    <div className="bg-gray-100">
      <Helmet>
        <title>{productDetails?.name}</title>
        <meta name="description" content={productDetails?.description} />
      </Helmet>

      <div className="container mx-auto px-4 py-8">
        <div className="flex flex-wrap">
          <div className="w-full md:w-1/2 px-4">
            <img
              src={images ? images[index] : ""}
              alt="Product"
              className="w-full h-auto lg:h-2/3 object-cover rounded-lg shadow-md mb-4"
              id="mainImage"
            />
            <div className="flex gap-4 justify-center overflow-x-auto">
              {images?.map((item: any, i: number) => (
                <img
                  key={i}
                  alt="Thumbnail 1"
                  className={`size-16 sm:size-20 object-cover rounded-md cursor-pointer  hover:opacity-100 transition duration-300 ${
                    i === index ? "opacity-100" : "opacity-60"
                  } `}
                  src={item}
                  onClick={() => {
                    setIndex(i);
                  }}
                />
              ))}
            </div>
          </div>

          <div className="w-full md:w-1/2 px-4 ">
            <h2 className="text-3xl font-bold text-gray-700 my-2">
              {productDetails?.name}
            </h2>
            <div className="mb-4 flex items-center">
              <span className="text-gray-600 line-through flex items-center">
                <TbCurrencyTaka className="text-xl" />
                {productDetails?.regular_price}
              </span>
              <span className="text-2xl text-gray-800 font-bold mr-2 flex items-center">
                <TbCurrencyTaka className="text-3xl" />
                {productDetails?.offer_price}
              </span>
            </div>

            <p className="text-gray-700 mb-6">{productDetails?.description}</p>

            <div className="mb-6">
              <label
                htmlFor="quantity"
                className="block text-sm font-medium text-gray-700 mb-1"
              >
                Quantity:
              </label>

              <button
                disabled={quantity === 1}
                onClick={(e) => setQuantity(quantity - 1)}
                className="px-4 py-2  text-gray-500 bg-white text-center rounded-md border-gray-500  shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 font-bold"
              >
                -
              </button>
              <input
                type="text"
                value={quantity}
                className="w-12 text-center rounded-md border-gray-300  shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 mx-2"
              />
              <button
                onClick={(e) => setQuantity(quantity + 1)}
                className="px-4 py-2 bg-white   text-center rounded-md border-gray-300  shadow-sm text-gray-500 focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 font-bold"
              >
                +
              </button>
            </div>

            <div className="flex space-x-4 mb-6">
              <button
                onClick={() => addToCart(cartProduct)}
                className="bg-gray-600 flex gap-2 items-center text-white px-6 py-2 rounded-md hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  className="size-6"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M2.25 3h1.386c.51 0 .955.343 1.087.835l.383 1.437M7.5 14.25a3 3 0 0 0-3 3h15.75m-12.75-3h11.218c1.121-2.3 2.1-4.684 2.924-7.138a60.114 60.114 0 0 0-16.536-1.84M7.5 14.25 5.106 5.272M6 20.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0Zm12.75 0a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0Z"
                  />
                </svg>
                Add to Cart
              </button>
              <button
                onClick={() => {
                  buyNowHan(cartProduct);
                }}
                className="bg-main flex gap-2 items-center  text-white px-6 py-2 rounded-md hover:bg-fuchsia-600 focus:outline-none focus:ring-2"
              >
                Buy Now
              </button>
            </div>

            <div>
              <h3 className="text-lg font-semibold mb-2 text-gray-800">
                Key Features:
              </h3>
              <ul className="list-none list-inside text-gray-700">
                <li className="flex items-center mb-3">
                  <TbTruckDelivery className="mr-3" /> Delivery inside Dhaka
                  within 3 days Outside Dhaka 3-5 Days.
                </li>
                <li className="flex items-center mb-3">
                  <FaAward className="mr-3" /> 100% Original Branded Products.
                </li>
                <li className="flex items-center mb-3">
                  <BsCashCoin className="mr-3" /> Cash on delivery available.
                </li>
                <li className="flex items-center mb-3">
                  <GiReturnArrow className="mr-3" /> Easy Product Return.
                  <Link to={"/refund-return"} className="underline">
                    {" "}
                    T&C
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="my-2">
          <h4 className="text-gray-800 font-bold ">DESCRIPTION</h4>

          <div
            className="text-gray-700 break-words"
            dangerouslySetInnerHTML={{ __html: productDetails?.details || "" }}
          ></div>
        </div>

        {/* You may also like */}
        <div className="relative">
          <h2 className="text-2xl font-bold my-4 text-gray-800">
            You may also like
          </h2>

          {/* Left Scroll Button */}
          <button
            onClick={scrollLeft}
            className="absolute left-0 top-1/2 transform -translate-y-1/2 p-2 bg-gray-800 text-white rounded-full z-10"
          >
            &#8249;
          </button>

          {/* Right Scroll Button */}
          <button
            onClick={scrollRight}
            className="absolute right-0 top-1/2 transform -translate-y-1/2 p-2 bg-gray-800 text-white rounded-full z-10"
          >
            &#8250;
          </button>

          <div
            ref={scrollContainerRef}
            className="overflow-x-auto scroll-smooth flex space-x-4 scrollbar-hide"
          >
            {products?.map((item: any) => (
              <div key={item.id} className="max-w-[200px]">
                <div
                  className="relative shadow p-4 hover:shadow-lg cursor-pointer bg-gray-200"
                  onClick={() => {
                    navigate(`/products /details/${item.id}`);
                  }}
                >
                  <div className="relative aspect-square overflow-hidden group">
                    <img
                      className="group-hover:scale-125 h-full w-full object-cover transition-all duration-300"
                      src={item.image_url}
                      alt=""
                    />
                  </div>

                  {item.off_discount ? (
                    <div className="absolute top-0 m-1 rounded-full bg-white">
                      <p className="text-[10px] rounded-full bg-black p-1 font-bold uppercase tracking-wide text-white sm:px-3 sm:py-1">
                        {item.off_discount}% off
                      </p>
                    </div>
                  ) : (
                    <></>
                  )}

                  <h3 className="text-xs font-semibold mt-4 text-gray-600">
                    {item.name.slice(0, 25)}
                  </h3>

                  <div className="flex items-center justify-between mt-4">
                    {item.offer_price ? (
                      <del className="mt-px text-xs font-semibold text-gray-600  whitespace-nowrap">
                        {Number(item.regular_price)} BDT
                      </del>
                    ) : (
                      <></>
                    )}

                    <p className="text-xs font-bold   text-purple-700 whitespace-nowrap">
                      {Number(item.offer_price || item.regular_price)} BDT
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProductDetails;
