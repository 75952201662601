import { Link } from "react-router-dom";
import "../styles/heroBanner.css";

const heroBanner = {
  title: "UP TO 30% OFF",
  seconderyTitle: "IN K-BEAUTY PRODUCTS",
  description: "For limited time only",
  buttonLebel: "SHOP NOW",
  imgUrl:
    "https://images.unsplash.com/photo-1615397349754-cfa2066a298e?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1887&q=80",
};

const HeroBanner = () => {
  return (
    <section className="px-3  bg-neutral-100 py-10">
      <div className="grid lg:grid-cols-2 items-center justify-items-center gap-5">
        <div className="order-2 lg:order-1 flex flex-col justify-center items-center">
          <p className="text-2xl font-bold md:text-5xl text-main">
            {heroBanner.title}
          </p>
          <p className="text-2xl font-bold md:text-5xl">
            {heroBanner.seconderyTitle}
          </p>
          <p className="mt-2 text-sm md:text-lg">{heroBanner.description} </p>
          <Link to={"/products"}>
            <button className="text-base md:text-lg bg-main text-white pt-1 pb-2 px-5 mt-10 hover:bg-purple-800 rounded-full ">
              {heroBanner.buttonLebel}
            </button>
          </Link>
        </div>
        <div className="order-1 lg:order-2 hidden  lg:block">
          <img
            className="h-80 w-80 object-cover lg:w-[400px] lg:h-[400px]"
            src={heroBanner.imgUrl}
            alt=""
          />
        </div>
      </div>
    </section>
  );
};

export default HeroBanner;
