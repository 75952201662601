import React, { useEffect, useState } from "react";

interface ToastProps {
  message: string;
  duration?: number; // Duration in milliseconds, default is 3000ms
  type?: "success" | "error" | "info"; // Different types for styling
}

const Toast: React.FC<ToastProps> = ({
  message,
  duration = 3000,
  type = "info",
}) => {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(false);
    }, duration);

    return () => clearTimeout(timer);
  }, [duration]);

  const getToastStyle = () => {
    switch (type) {
      case "success":
        return "bg-green-500 text-white";
      case "error":
        return "bg-red-500 text-white";
      case "info":
        return "bg-blue-500 text-white";
      default:
        return "bg-gray-500 text-white";
    }
  };

  if (!isVisible) return null;

  return (
    <div
      className={`fixed lg:top-18 md:top-10 sm:top-4 lg:right-32 md:right-18 sm:right-10 px-4 py-3 rounded shadow-lg ${getToastStyle()} transition-transform transform`}
    >
      <p>{message}</p>
    </div>
  );
};

export default Toast;
