import { Link } from "react-router-dom";
import logo from "../assest/logo.png";
import { useGetCategoryQuery } from "../redux/api/authApi";
type Props = {};

const contactInfo = {
  address: "Hazaribari, Mohakhali Wireless, Dhaka-1212",
  mobile: "+88 01880-414733",
  email: "info@cosmelic.com",
};

function FooterPage({}: Props) {
  const { data, isLoading } = useGetCategoryQuery("size=5");
  return (
    <footer className="">
      <div className="mx-auto grid max-w-screen-xl gap-y-8 gap-x-12 px-4 py-10 sm:px-20 md:grid-cols-2 xl:grid-cols-3 xl:px-10">
        <div className="max-w-sm">
          <div className="mb-6 flex h-10 items-center space-x-2">
            <Link to={"/"}>
              <img className="h-10 object-contain" src={logo} alt="" />
            </Link>
          </div>

          <div className="">
            <div className="flex items-center text-gray-600 dark:text-gray-400">
              <svg
                fill="none"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1.5"
                viewBox="0 0 24 24"
                className="w-4 h-4 text-gray-800"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="1.5"
                  d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                />
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="1.5"
                  d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                />
              </svg>
              <div className="ml-2 text-gray-800">{contactInfo.address}</div>
            </div>

            <div className="flex items-center mt-4 text-gray-600 dark:text-gray-400">
              <svg
                fill="none"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1.5"
                viewBox="0 0 24 24"
                className="w-4 h-4 text-gray-800"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="1.5"
                  d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
                />
              </svg>
              <div className="ml-2 text-gray-800">{contactInfo.mobile}</div>
            </div>

            <div className="flex items-center mt-2 text-gray-600 dark:text-gray-400">
              <svg
                fill="none"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1.5"
                viewBox="0 0 24 24"
                className="w-4 h-4 text-gray-800"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="1.5"
                  d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                />
              </svg>
              <div className="ml-2 text-gray-800">{contactInfo.email}</div>
            </div>
          </div>

          <hr className="my-4" />

          <div className="flex space-x-5">
            <a
              href="https://www.facebook.com/cosmelics?mibextid=ZbWKwL"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="https://img.icons8.com/fluent/30/000000/facebook-new.png"
                alt="logo"
              />
            </a>

            <a
              href="https://www.instagram.com/cos_melic?igsh=MTBtbmxoYWY3NGFxZQ=="
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="https://img.icons8.com/fluent/30/000000/instagram-new.png"
                alt="logo"
              />
            </a>
            <a
              href="https://m.me/cosmelics"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="https://img.icons8.com/fluent/30/000000/facebook-messenger--v2.png"
                alt="logo"
              />
            </a>
            <a
              href="https://wa.me/8801626074776"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="https://img.icons8.com/fluent/30/000000/whatsapp.png"
                alt="logo"
              />
            </a>
          </div>
        </div>
        <div className="">
          <div className="mt-4 mb-2 font-medium xl:mb-4 text-main">
            TOP CATEGORIES
          </div>
          <nav aria-label="Guides Navigation" className="text-gray-500">
            {isLoading ? (
              <></>
            ) : (
              <ul className="space-y-3">
                {data?.map((item, index) => {
                  return (
                    <li key={index}>
                      <Link
                        className="hover:text-blue-600"
                        to={`/products?category=${item.id}`}
                      >
                        {item?.name}
                      </Link>
                    </li>
                  );
                })}
              </ul>
            )}
          </nav>
        </div>

        <div className="">
          <div className="mt-4 mb-2 font-medium xl:mb-4 text-main">HELP</div>
          <nav aria-label="Footer Navigation" className="text-gray-500">
            <ul className="space-y-3">
              <li>
                <Link to={"/refund-return"} className="hover:text-blue-600 ">
                  REFUND & RETURN POLICY
                </Link>
              </li>
              <li>
                <Link to={"/t&c"} className="hover:text-blue-600 ">
                  TERMS & CONDITIONS
                </Link>
              </li>
              <li>
                <Link to={"/shipping"} className="hover:text-blue-600 ">
                  SHIPPING & DELIVERY
                </Link>
              </li>
              <li>
                <Link to={"/privecy"} className="hover:text-blue-600 ">
                  PRIVACY POLICY
                </Link>
              </li>
              <li>
                <Link
                  to={"/faq"}
                  className="hover:text-blue-600 hover:underline"
                >
                  FAQS
                </Link>
              </li>
            </ul>
          </nav>
        </div>
      </div>

      <div className="border-t">
        <div className="px-4 py-3 text-gray-700 sm:px-20">
          <p className="text-center">© 2024 Cosmelic | All Rights Reserved</p>
        </div>
      </div>
    </footer>
  );
}

export default FooterPage;
