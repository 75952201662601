import { useState } from "react";
import { IoMdClose } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  decrementQuantity,
  incrementQuantity,
  removeItem,
} from "../redux/slices/cartSlice";
import { RootState } from "../redux/store/store";
import Toast from "../utils/Toast";

const Drawer = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDrawer = () => {
    setIsOpen(!isOpen);
  };

  const cartItems = useSelector((state: RootState) => state.cart.items);
  const getTotalItems = (): number => {
    const totalQuantity = cartItems.reduce(
      (total, item) => total + item.quantity,
      0
    );

    return totalQuantity || 0;
  };

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const handleCheckout = async () => {
    navigate(`/checkout`);
    return <Toast message="Redirecting..." />;
  };

  // Function to increase the quantity of an item
  const incrementQuantityHandler = (id: number | string) => {
    dispatch(incrementQuantity(id));
  };

  // Function to decrease the quantity of an item
  const decrementQuantityHandler = (id: number | string) => {
    dispatch(decrementQuantity(id));
  };

  const removeItemHandler = (id: number | string) => {
    dispatch(removeItem(id));
  };

  // Function to calculate total, discount, and net prices
  const calculateTotals = () => {
    return cartItems.reduce(
      (acc, item) => {
        const itemTotalPrice = Number(item.regular_price) * item.quantity;
        const itemTotalDiscount =
          (Number(item.regular_price) -
            (Number(item?.offer_price) || Number(item.regular_price))) *
          item.quantity;
        const itemNetPrice =
          (Number(item.offer_price) || Number(item.regular_price)) *
          item.quantity;

        return {
          totalPrice: acc.totalPrice + itemTotalPrice,
          totalDiscount: acc.totalDiscount + itemTotalDiscount,
          netPrice: acc.netPrice + itemNetPrice,
        };
      },
      { totalPrice: 0, totalDiscount: 0, netPrice: 0 } // Initial values
    );
  };

  const { netPrice, totalDiscount, totalPrice } = calculateTotals();

  const trackPurchase = (value: any) => {
    if (window.fbq) {
      window.fbq("track", "Purchase", {
        value: value, // the value of the purchase
        currency: "BDT", // e.g., 'USD'
      });
    }
  };

  return (
    <div className="relative">
      {/* Button to toggle the drawer */}

      <button
        className="p-2 text-white rounded-md relative"
        onClick={toggleDrawer}
      >
        <div className="t-0 absolute left-8">
          <p className="flex h-2 w-2 items-center justify-center rounded-full bg-main p-3 text-xs text-white">
            {getTotalItems()}
          </p>
        </div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          className="file: mt-4 h-6 w-12 text-gray-900"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M2.25 3h1.386c.51 0 .955.343 1.087.835l.383 1.437M7.5 14.25a3 3 0 00-3 3h15.75m-12.75-3h11.218c1.121-2.3 2.1-4.684 2.924-7.138a60.114 60.114 0 00-16.536-1.84M7.5 14.25L5.106 5.272M6 20.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zm12.75 0a.75.75 0 11-1.5 0 .75.75 0 011.5 0z"
          />
        </svg>
      </button>

      {/* Drawer */}
      <div
        className={`fixed top-0 right-0 h-full text-white bg-gray-100 transition-transform transform ${
          isOpen ? "translate-x-0" : "translate-x-full"
        } z-50`}
      >
        <button
          className="p-2 mt-4 bg-red-500 rounded-md ml-4"
          onClick={toggleDrawer}
        >
          <IoMdClose />
        </button>

        <div className="relative">
          <section className="h-[95vh] overflow-y-auto">
            <div className="mx-auto px-4 sm:px-6 lg:px-8">
              <div className="mx-auto max-w-2xl md:mt-12">
                <div className="bg-white shadow">
                  <div className="px-4 sm:px-8 sm:py-10">
                    <div className="flow-root">
                      <ul className="-my-8">
                        {cartItems?.map((item, index) => {
                          return (
                            <li
                              className="flex flex-col space-y-3 py-6 text-left sm:flex-row sm:space-x-5 sm:space-y-0"
                              key={index}
                            >
                              <div className="shrink-0">
                                <img
                                  className="h-24 w-24 max-w-full rounded-lg object-cover"
                                  src={item.image_url}
                                  alt=""
                                />
                              </div>

                              <div className="relative flex flex-1 flex-col justify-between">
                                <div className="sm:col-gap-5 sm:grid sm:grid-cols-2">
                                  <div className="pr-8 sm:pr-5">
                                    <p className="text-base font-semibold text-gray-900">
                                      {item.name}
                                    </p>
                                    <p className="mx-0 mt-1 mb-0 text-sm text-gray-400">
                                      36EU - 4US
                                    </p>
                                  </div>

                                  <div className="mt-4 flex items-end justify-between sm:mt-0 sm:items-start sm:justify-end">
                                    <p className="shrink-0 w-20 text-base font-semibold text-gray-900 sm:order-2 sm:ml-8 sm:text-right whitespace-nowrap">
                                      {Number(item.regular_price)}
                                    </p>

                                    <div className="sm:order-1">
                                      <div className="mx-auto flex h-8 items-stretch text-gray-600">
                                        <button
                                          disabled={item.quantity < 2}
                                          onClick={() =>
                                            decrementQuantityHandler(item.id)
                                          }
                                          className="flex items-center justify-center rounded-l-md bg-gray-200 px-4 transition hover:bg-black hover:text-white"
                                        >
                                          -
                                        </button>
                                        <div className="flex w-full items-center justify-center bg-gray-100 px-4 text-xs uppercase transition">
                                          {item.quantity}
                                        </div>
                                        <button
                                          onClick={() =>
                                            incrementQuantityHandler(item.id)
                                          }
                                          className="flex items-center justify-center rounded-r-md bg-gray-200 px-4 transition hover:bg-black hover:text-white"
                                        >
                                          +
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="absolute top-0 right-0 flex sm:bottom-0 sm:top-auto">
                                  <button
                                    type="button"
                                    className="flex rounded p-2 text-center text-gray-500 transition-all duration-200 ease-in-out focus:shadow hover:text-gray-900"
                                    onClick={() => removeItemHandler(item.id)}
                                  >
                                    <svg
                                      className="h-5 w-5"
                                      xmlns="http://www.w3.org/2000/svg"
                                      fill="none"
                                      viewBox="0 0 24 24"
                                      stroke="currentColor"
                                    >
                                      <path
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        stroke-width="2"
                                        d="M6 18L18 6M6 6l12 12"
                                        className=""
                                      ></path>
                                    </svg>
                                  </button>
                                </div>
                              </div>
                            </li>
                          );
                        })}
                      </ul>
                    </div>

                    <div className="mt-6 border-t border-b py-2">
                      <div className="flex items-center justify-between">
                        <p className="text-sm text-gray-400">Subtotal</p>
                        <p className="text-lg font-semibold text-gray-900">
                          {totalPrice}
                        </p>
                      </div>
                      <div className="flex items-center justify-between">
                        <p className="text-sm text-gray-400">Discount</p>
                        <p className="text-lg font-semibold text-gray-900">
                          {totalDiscount}
                        </p>
                      </div>
                    </div>
                    <div className="mt-6 flex items-center justify-between">
                      <p className="text-sm font-medium text-gray-900">Total</p>
                      <p className="text-2xl font-semibold text-gray-900">
                        <span className="text-xs font-normal text-gray-400">
                          BDT
                        </span>
                        {netPrice}
                      </p>
                    </div>

                    <div className="my-6 text-center">
                      <button
                        onClick={() => {
                          handleCheckout();
                          toggleDrawer();
                          trackPurchase(cartItems);
                        }}
                        type="button"
                        className="group inline-flex w-full items-center justify-center rounded-md bg-gray-900 px-6 py-2 md:py-3 lg:py-4 text-sm md:text-base lg:text-lg font-semibold text-white transition-all duration-200 ease-in-out focus:shadow hover:bg-gray-800"
                      >
                        Checkout
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="group-hover:ml-8 ml-4 h-6 w-6 transition-all"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          stroke-width="2"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M13 7l5 5m0 0l-5 5m5-5H6"
                          />
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>

      {/* Overlay */}
      {isOpen && (
        <div
          className="fixed inset-0 bg-black opacity-50 z-40"
          onClick={toggleDrawer}
        ></div>
      )}
    </div>
  );
};

export default Drawer;
