import { useEffect } from "react";

const RefundReturnPolicy = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <div className="bg-gray-50 p-8 text-gray-800">
      <h1 className="text-3xl font-bold mb-6 text-center text-main">
        Refund & Return Policy
      </h1>

      <section className="mb-6">
        <p className="text-gray-700 mb-4">
          We guarantee your satisfaction with all the platforms of Cosmelic. If
          you receive a damaged or defective item, wrong product, we will
          promptly send you a replacement or issue you a full refund after you
          have returned the damaged or defective product. You will not be
          charged any additional shipping fees for replacement of such damaged
          or defective shipments. Please see our Cancellation & Return Policy
          for more information.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-4">Refund & Return Policy</h2>
        <p className="text-gray-700 mb-4">
          We maintain a ‘closed box delivery’ policy. Which is crucial to ensure
          the authenticity of the products, privacy of the customers and product
          adulteration or modification prevention. If you receive a damaged,
          defective or wrong product, please return it to Cosmelic and we’ll
          arrange for a replacement provided that meets the following
          conditions:
        </p>

        <ul className="list-disc pl-6 text-gray-700 mb-4">
          <li>
            If any defect is found (damaged/ defective/ wrong product) after
            opening the box, inform the “Customer Relationship Management
            Department” (through inbox or hotline +880 1880414733) as soon as
            possible along with a picture/ video proof.
          </li>
          <li>
            The complaint must be made within 3 days from the day the product
            was received. Electronics items have a 15-day replacement guarantee
            for defective or non-functioning products.
          </li>
          <li>
            Used/Swatched or liquid/semi-liquid products will not be considered
            for exchange or refund.
          </li>
          <li>
            Products will not be exchanged or returned if the buyer changes
            their mind or dislikes the smell, texture, color, design, etc.
          </li>
          <li>
            The Return Policy is not valid after the product seal is broken or
            if the product does not suit you.
          </li>
          <li>
            If you mistakenly order a wrong product, we may exchange it upon
            payment of return and resending costs. The decision depends on the
            product type and management discretion.
          </li>
          <li>
            The original invoice, Cosmelic box, and intact packaging must be
            returned along with the product.
          </li>
          <li>
            Lingerie items (bottoms) are absolutely non-exchangeable or
            non-returnable, whether tried or not.
          </li>
        </ul>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-4">
          How to send your product back?
        </h2>
        <p className="text-gray-700 mb-4">
          Our customer service will arrange for a product pick-up from your
          address, free of delivery charge (conditions apply). If you live
          outside Dhaka, please send the product via Sundarban Courier Service.
          If your return claim is validated, we will reimburse your courier
          charge.
        </p>
        <p className="text-gray-700 mb-4">
          If your claim is not valid, you will have to accept the same product
          and bear the return and resending expenses.
        </p>
        <p className="text-gray-700 mb-4">
          For further support: Hotline +880 1880 414733; E-mail:
          complaint@Cosmelic.com
        </p>
      </section>

      <footer className="mt-8 text-center text-gray-500">
        Last updated: October 5, 2024
      </footer>
    </div>
  );
};

export default RefundReturnPolicy;
