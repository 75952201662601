import { useGetProductsQuery } from "../redux/api/authApi";
import Skeleton from "../utils/components/Skeleton";
import Product2 from "./Product";

type Props = {};

function Products(props: Props) {
  const { data, isLoading } = useGetProductsQuery("size=8");

  const products = data?.data;

  return (
    <section className="text-gray-700 bg-white lg:mt-12 md:mt-8 sm:mt-4">
      {isLoading ? (
        <Skeleton />
      ) : (
        <div className="grid grid-cols-2 lg:grid-cols-4 lg:gap-8 md:gap-6 gap-2 lg:p-6 md:p-4 p-2">
          {products?.map((product) => (
            <Product2 key={product.id} product={product} />
          ))}
        </div>
      )}
    </section>
  );
}

export default Products;
