import { Link } from "react-router-dom";
import { useGetLatestProductsQuery } from "../redux/api/authApi";
import Skeleton from "../utils/components/Skeleton";
import Product2 from "./Product";

type Props = {};

function LatestProducts(props: Props) {
  const { data: products, isLoading } = useGetLatestProductsQuery();

  return (
    <section className="text-gray-700 bg-white">
      <div className="flex items-center justify-between p-4 border">
        <h4 className="font-title">LATEST PRODUCTS</h4>
        <Link to="/products">
          <button className="px-6 py-2 text-white font-semibold rounded-md hover:bg-purple-800 transition duration-300 bg-main">
            All Products
          </button>
        </Link>
      </div>

      {isLoading ? (
        <Skeleton />
      ) : (
        <div className="grid grid-cols-2 lg:grid-cols-4 lg:gap-8 md:gap-6 gap-2 lg:p-6 md:p-4 p-2">
          {products?.map((product) => (
            <Product2 key={product.id} product={product} />
          ))}
        </div>
      )}
    </section>
  );
}

export default LatestProducts;
