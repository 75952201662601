import { useEffect } from "react";

const PrivacyPolicy = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <div className="bg-white p-8 text-gray-800">
      <h1 className="text-3xl font-bold mb-6 text-center">
        Privacy and Confidentiality
      </h1>

      <p className="text-gray-700 mb-4 font-bold text-xl">
        Here's a revised privacy policy draft tailored for cosmelic.com, with
        the email address info@cosmelic.com:
      </p>

      <section className="mb-6">
        <p className="text-gray-700 mb-4">
          Welcome to cosmelic.com At cosmelic.com, we prioritize your privacy
          and are committed to protecting your personal information. This
          Privacy Policy outlines how we collect, use, and disclose your data.
        </p>
      </section>
      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-4">1. Data Collection</h2>
        <p className="text-gray-700 mb-4">
          We may collect various types of information when you interact with our
          website, including:
        </p>
        <ul className="list-disc list-inside mb-4">
          <li>
            Personal Information: Name, email address, shipping address, billing
            address, phone number, payment information.
          </li>
          <li>
            Usage Data: Information about how you use our website, such as your
            IP.
          </li>
          <li>
            Usage Data: Information about how you use our website, such as your
            IP address, browser type, and pages visited
          </li>
          <li>
            Order History: Details of your purchases, including product
            information and order dates.
          </li>
        </ul>
      </section>
      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-4">2. Data Usage</h2>
        <p className="text-gray-700 mb-4">
          We use your information for the following purposes:
        </p>
        <ul className="list-disc list-inside mb-4">
          <li>
            Processing Orders: Fulfil your orders, process payments, and manage
            shipping.
          </li>
          <li>
            Customer Service: Provide assistance, respond to inquiries, and
            address issues.
          </li>
          <li>
            Personalized Experience: Tailor our website and marketing
            communications to your preferences.
          </li>
          <li>
            Product Improvement: Analyse customer data to improve our product
            offerings and website features.
          </li>
          <li>
            Legal Compliance: Comply with applicable laws and regulations.
          </li>
        </ul>
      </section>
      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-4">3. Data Sharing</h2>
        <p className="text-gray-700 mb-4">
          We may share your information with:
        </p>
        <ul className="list-disc list-inside mb-4">
          <li>
            Third-Party Service Providers: To assist with order fulfilment,
            payment processing, shipping, marketing, and customer support.
          </li>
          <li>
            Legal Authorities: If required by law or to protect our rights or
            the rights of others.
          </li>
        </ul>
      </section>
      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-4">4. Data Security</h2>
        <p className="text-gray-700 mb-4">
          We implement robust security measures to protect your data, including:{" "}
        </p>
        <ul className="list-disc list-inside mb-4">
          <li>
            Encryption: Using encryption technology to secure data transmission.
          </li>
          <li>
            Access Controls: Restricting access to your data to authorized
            personnel.
          </li>
          <li>
            Regular Monitoring: Monitoring our systems for security
            vulnerabilities and threats.
          </li>
        </ul>
      </section>
      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-4">5. Your Rights</h2>
        <p className="text-gray-700 mb-4">You have the right to:</p>
        <ul className="list-disc list-inside mb-4">
          <li>Access: Request a copy of your personal information.</li>
          <li>Rectification: Correct any inaccuracies in your data.</li>
          <li>Erasure: Request the deletion of your data.</li>
          <li>Object: Object to certain data processing activities.</li>
          <li>
            Data Portability: Request a copy of your data in a structured,
            commonly used format.
          </li>
        </ul>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-4">6. Cookies and Tracking</h2>
        <p className="text-gray-700 mb-4">
          We use cookies and similar tracking technologies to collect
          information about your website usage and preferences. You can manage
          your cookie settings through your browser.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-4">7. Contact Us</h2>
        <p className="text-gray-700 mb-4">
          If you have any questions or concerns about our privacy practices,
          please contact us at:{" "}
          <a
            href="mailto:info@cosmelic.com
"
            className="text-blue-500 underline"
          >
            Email: info@cosmelic.com
          </a>
          . Using cosmelic.com, you consent to our collection and use of your
          information as described in this Privacy Policy.
        </p>
      </section>

      <footer className="mt-8 text-center text-gray-500">
        Last updated: October 5, 2024
      </footer>
    </div>
  );
};

export default PrivacyPolicy;
