// src/store/api/authApi.ts

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {
  IPlaceOrder,
  IProductDetailsInterface,
  IProductInterface,
} from "../../interfaces/common.interfaces";
import { ILoginResponse, IResponse } from "../../interfaces/user.interface";

interface LoginRequest {
  username: string;
  password: string;
}

export const authApi = createApi({
  reducerPath: "authApi",
  baseQuery: fetchBaseQuery({
    // baseUrl: "http://localhost:4444",
    baseUrl: "https://api.cosmelic.com",
    prepareHeaders: (headers, { getState }) => {
      const token = localStorage.getItem("token");

      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    login: builder.mutation<ILoginResponse, LoginRequest>({
      query: (credentials) => ({
        url: "/users/login",
        method: "POST",
        body: credentials,
      }),
    }),

    refreshToken: builder.query<ILoginResponse, string>({
      query: () => ({
        url: "/users/refresh-token",
        method: "GET",
      }),
    }),

    // CATEGORY
    getCategory: builder.query<
      { id: number; img: string; name: string }[],
      string
    >({
      query: (query) => ({
        url: `/products/category?${query}`,
        method: "GET",
      }),
    }),

    // ALL PRoDUCT
    getProducts: builder.query<IResponse<IProductInterface[]>, string>({
      query: (query) => ({
        url: `/products?${query}`,
        method: "GET",
      }),
    }),

    // LATEST PRODUCTS
    getLatestProducts: builder.query<IProductInterface[], void>({
      query: () => ({
        url: "/products/latest",
        method: "GET",
      }),
    }),

    // PRODUCT DETAILS
    getProductsDetails: builder.query<
      IResponse<IProductDetailsInterface>,
      number
    >({
      query: (id) => ({
        url: `/products/${id}`,
        method: "GET",
      }),
    }),

    // ORDERS
    placeOrder: builder.query<IResponse<number>, IPlaceOrder>({
      query: (body) => ({
        url: "/products/order",
        method: "POST",
        body,
      }),
    }),
  }),
});

export const {
  useLoginMutation,
  useLazyRefreshTokenQuery,
  useRefreshTokenQuery,
  useGetCategoryQuery,
  useGetProductsQuery,
  useGetProductsDetailsQuery,
  useGetLatestProductsQuery,
  usePlaceOrderQuery,
  useLazyPlaceOrderQuery,
} = authApi;
