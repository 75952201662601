import { useEffect } from "react";

const TermsAndConditions = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <div className="bg-gray-50 p-8 text-gray-800">
      <h1 className="text-3xl font-bold mb-6 text-center text-main">
        Terms & Conditions
      </h1>

      <section className="mb-6">
        <p className="text-gray-700 mb-4">
          Welcome to cosmelic.com, hereby known as “we,” “us,” or “Cosmelic”.
          Please read these Terms & Conditions carefully before using this site.
          By using the Site, you hereby accept these terms and conditions and
          agree to comply with them (the “User Agreement”). This User Agreement
          is effective upon your use of the Site, signifying your acceptance of
          these terms. If you do not agree to be bound by this User Agreement,
          please do not access, register with, or use this site. This site is
          owned and operated by Cosmelic.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-4">
          Changes to Terms & Conditions
        </h2>
        <p className="text-gray-700 mb-4">
          The Site reserves the right to change, modify, add, or remove portions
          of these Terms and Conditions at any time without any prior
          notification. Changes will be effective when posted on the Site, with
          no other notice provided. Please check these Terms and Conditions
          regularly for updates. Your continued use of the site following the
          posting of changes to these Terms and Conditions constitutes your
          acceptance of those changes.
        </p>
      </section>

      <footer className="mt-8 text-center text-gray-500">
        Last updated: October 5, 2024
      </footer>
    </div>
  );
};

export default TermsAndConditions;
